<template>
  <v-row
    class="page-wrap d-flex flex-wrap flex-sm-wrap flex-md-nowrap flex-lg-nowrap flex-xl-nowrap"
  >
    <div>
      <v-dialog
        v-model="submitReviewDialog"
        persistent
        max-width="450"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <v-card class="review-dialog-wrap">
          <v-card-title
            ><span class="headline primary--text">Review</span></v-card-title
          >

          <v-card-text>
            <div>
              <v-form
                ref="reviewForm"
                v-model="valid"
                lazy-validation
                @submit.prevent
              >
                <v-row>
                  <v-col cols="12" class="pb-3">
                    <label>Decission</label>
                  </v-col>
                  <v-col cols="12" class="py-0 pb-3">
                    <v-select
                      v-model="reviewItem.status"
                      :items="approvalStates"
                      :rules="requiredRule"
                      outlined
                      autocomplete
                      item-value="id"
                      item-text="name"
                      hide-details="auto"
                      dense
                    />
                    <p
                      v-if="reviewItem && reviewItem.status === 'rejected'"
                      class="error--text text--darken-1 mb-0 pl-2 pt-2 pr-2"
                    >
                      Beware any rejection is final. If you want to let the
                      requestor make changes please select
                      <strong>"Pending Changes"</strong>
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-3">
                    <label>Comments</label>
                  </v-col>
                  <v-col cols="12" class="py-0 pb-3">
                    <vue-editor
                      id="edit-review-comments"
                      v-model="reviewItem.comments"
                      :editor-options="editorConfig"
                      placeholder="Please specify the reasoning behind your decission."
                      class="pb-3"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-row class="pb-3">
              <v-spacer></v-spacer>
              <v-btn color="grey" small depressed text @click="closeDialog"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                small
                rounded
                class="mr-6"
                @click="submitReview"
                >Submit</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- Review Dialog -->

    <v-dialog
      v-model="mobileReviewDetailsDialog"
      persistent
      max-width="500"
      fullscreen
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title v-if="activeItem" class="subtitle-1 text-uppercase">
            <div class="text-truncate pt-4">
              <span v-if="activeItem.auditType !== 'MANUFACTURINGORDER'">{{
                activeItem.reviewAssetName
              }}</span
              ><span v-else
                >Manufacturing Order #{{ activeItem.reviewAssetID }}</span
              >
            </div>

            <v-spacer></v-spacer>

            <v-chip
              pill
              x-small
              :color="
                reviewChipColor(activeItem.auditType) +
                  ' lighten-3 font-weight-bold upperCaseSpacing'
              "
              :text-color="reviewChipColor(activeItem.auditType) + ' darken-3'"
              >{{ auditType(activeItem.auditType) }}</v-chip
            >
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon @click="mobileReviewDetailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text v-if="activeItem" class="mt-4">
          <review-details
            :active-item="activeItem"
            @submit-review="submitReviewDialog = true"
          ></review-details>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Left Column -->
    <v-col cols="12" sm="12" md="4" class=" flex-grow-1 bkground">
      <div class="scroll-container py-0 pr-0">
        <v-card flat class="pt-0">
          <v-list
            v-if="!$apollo.loading && myOpenReviews.length > 0"
            dense
            class="py-0"
          >
            <v-list-item-group v-model="reviewIdx" color="primary">
              <template v-for="(item, index) in myOpenReviews">
                <v-list-item :key="item.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters>
                        <div class="text-truncate">
                          <span
                            v-if="item.auditType !== 'MANUFACTURINGORDER'"
                            >{{ item.reviewAssetName }}</span
                          ><span v-else
                            >Manufacturing Order #{{ item.reviewAssetID }}</span
                          >
                        </div>
                        <v-spacer></v-spacer>

                        <v-chip
                          pill
                          x-small
                          :color="
                            reviewChipColor(item.auditType) +
                              ' lighten-3 font-weight-bold upperCaseSpacing'
                          "
                          :text-color="
                            reviewChipColor(item.auditType) + ' darken-3'
                          "
                          >{{ auditType(item.auditType) }}</v-chip
                        >
                      </v-row>
                    </v-list-item-title>
                    <v-list-item-subtitle class="pt-2">
                      <v-row no-gutters>
                        <div class="font-weight-regular">
                          <span class="caption grey--text">Submitted by: </span>
                          <span>{{
                            item.submitterFirstName +
                              ' ' +
                              item.submitterLastName
                          }}</span>
                        </div>
                        <v-spacer></v-spacer>

                        <span class="ml-3 caption grey--text">
                          {{ item.createdAt | moment('from', 'now') }}</span
                        >
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index + 1 < myOpenReviews.length"
                  :key="item.id"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>

          <div v-else-if="!$apollo.loading && myOpenReviews.length === 0">
            <message-box
              icon="mdi-emoticon-happy-outline"
              title="No Reviews"
              caption="Great, you've worked through all your reviews!"
              caption-color="grey--text text--darken-2"
            ></message-box>
          </div>

          <div v-else>
            <v-skeleton-loader
              v-for="line in 3"
              :key="line"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </div>
        </v-card>
      </div>
    </v-col>

    <!-- Right Column -->
    <v-col
      cols="12"
      sm="12"
      md="8"
      class="flex-grow-1 bkground pl-0 hidden-sm-and-down"
    >
      <div class="scroll-container py-0 pr-0">
        <v-card flat class="pt-0">
          <v-card-title v-if="activeItem" class="primary--text">
            <h3 class="text-truncate">
              <span v-if="activeItem.auditType !== 'MANUFACTURINGORDER'">{{
                activeItem.reviewAssetName
              }}</span
              ><span v-else
                >Manufacturing Order #{{ activeItem.reviewAssetID }}</span
              >
            </h3>

            <v-spacer></v-spacer>

            <v-chip
              pill
              x-small
              :color="
                reviewChipColor(activeItem.auditType) +
                  ' lighten-3 font-weight-bold upperCaseSpacing'
              "
              :text-color="reviewChipColor(activeItem.auditType) + ' darken-3'"
              >{{ auditType(activeItem.auditType) }}</v-chip
            >
          </v-card-title>

          <v-card-text v-if="activeItem">
            <review-details
              :active-item="activeItem"
              @submit-review="submitReviewDialog = true"
            ></review-details>
          </v-card-text>

          <div v-if="$apollo.loading">
            <v-skeleton-loader
              v-for="line in 3"
              :key="line"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </div>

          <div v-if="!$apollo.loading && !activeItem">
            <message-box
              title="Nothing Selected"
              caption="Select a review from the left to review"
              caption-color="grey--text text--darken-2"
            ></message-box>
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { getName } from 'country-list'
import MessageBox from '@/components/core/MessageBox'
import ReviewDetails from '@/modules/reviews/components/core/ReviewDetails'
import MY_OPEN_REVIEWS from '@/graphql/MyOpenReviews.gql'
import PRODUCT_REVIEW from '@/graphql/ProductReview.gql'
import BUDGET_REVIEW from '@/graphql/BudgetReview.gql'
import PROJECT_REVIEW from '@/graphql/ProjectReview.gql'
import PURCHASE_ORDER_REVIEW from '@/graphql/PurchaseOrderReview.gql'
import MANUFACTURING_ORDER_REVIEW from '@/graphql/ManufacturingOrderReview.gql'

export default {
  name: 'ReviewList',
  components: { MessageBox, ReviewDetails },
  data: () => ({
    drawer: true,
    activeItem: null,
    reviewIdx: null,
    reviewHeaders: [
      { text: 'Type', value: 'auditType', sortable: true },
      { text: '', value: 'reviewAssetName', sortable: false },
      { text: 'Submitted By', value: 'firstName', sortable: false },
      { text: 'Department', value: 'departmentName', sortable: false }
      // { text: 'Status', value: 'status', sortable: false }
    ],
    myOpenReviews: [],
    mobileReviewDetailsDialog: false,
    valid: true,
    submitReviewDialog: false,
    approvalStates: [
      { id: 'approved', name: 'Approved' },
      { id: 'pendingchanges', name: 'Pending Changes' },
      { id: 'rejected', name: 'Rejected' }
    ],
    reviewItem: {
      status: 'approved',
      comments: null
    },
    editorConfig: {
      modules: {
        toolbar: false
      }
    },
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    processing: false
  }),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    reviewType() {
      return this.activeItem.auditType
    }
  },
  apollo: {
    myOpenReviews: { query: MY_OPEN_REVIEWS, fetchPolicy: 'network-only' }
  },
  watch: {
    reviewIdx(val) {
      this.activeItem = this.myOpenReviews[val]
      if (this.$vuetify.breakpoint.smAndDown) {
        this.mobileReviewDetailsDialog = true
      }
    }
  },
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    auditType(item) {
      if (item === 'PROJECT') {
        return 'Project'
      } else if (item === 'BUDGET') {
        return 'Budget'
      } else if (item === 'PRODUCT') {
        return 'Product'
      } else if (item === 'MANUFACTURINGORDER') {
        return 'Manufacturing Order'
      } else if (item === 'PURCHASEORDER') {
        return 'Purchase Order'
      } else {
        return 'Misc'
      }
    },
    reviewChipColor(item) {
      if (item === 'PROJECT') {
        return 'info'
      } else if (item === 'BUDGET') {
        return 'indigo'
      } else if (item === 'PRODUCT') {
        return 'blue-grey'
      } else if (item === 'MANUFACTURINGORDER') {
        return 'green'
      } else if (item === 'PURCHASEORDER') {
        return 'teal'
      } else {
        return 'grey'
      }
    },
    stateColor: item => {
      if (item === 'review' || item === 'open') {
        return 'info lighten-2'
      } else if (item === 'pendingchanges') {
        return 'warning'
      } else if (item === 'rejected') {
        return 'error'
      } else if (item === 'approved') {
        return 'success'
      }
    },
    navToReview(item) {
      if (item.auditType === 'PROJECT') {
        this.$router.push({
          name: 'ProjectDetail',
          params: { projectID: item.reviewAssetID }
        })
      } else if (item.auditType === 'BUDGET') {
        this.$router.push({
          name: 'BudgetDetail',
          params: { budgetID: item.reviewAssetID }
        })
      } else if (item.auditType === 'PURCHASEORDER') {
        this.$router.push({
          name: 'PurchaseOrderDetail',
          params: { purchaseOrderID: item.reviewAssetID }
        })
      } else if (item.auditType === 'MANUFACTURINGORDER') {
        this.$router.push({
          name: 'ManufacturingOrderDetail',
          params: { manufacturingOrderID: item.reviewAssetID }
        })
      }
    },
    closeDialogs() {
      this.mobileReviewDetailsDialog = false
      this.closeDialog()
    },
    closeDialog() {
      this.submitReviewDialog = false
      this.reviewIdx = null
      if (this.myOpenReviews && this.myOpenReviews.length > 0) {
        this.reviewIdx = 0
      }

      this.reviewItem = {
        status: 'approved',
        comments: null
      }
    },
    submitReview() {
      this.processing = true
      var mutation = null

      var data = {
        status: this.reviewItem.status,
        comments: this.reviewItem.comments
      }

      switch (this.reviewType) {
        case 'BUDGET':
          mutation = BUDGET_REVIEW
          if (this.$refs.reviewForm.validate()) {
            data.budgetID = this.activeItem.reviewAssetID
            this.processReview(mutation, data)
          }
          break
        case 'PROJECT':
          mutation = PROJECT_REVIEW
          if (this.$refs.reviewForm.validate()) {
            data.projectID = this.activeItem.reviewAssetID
            this.processReview(mutation, data)
          }
          break
        case 'PURCHASEORDER':
          mutation = PURCHASE_ORDER_REVIEW
          if (this.$refs.reviewForm.validate()) {
            data.purchaseOrderID = this.activeItem.reviewAssetID
            this.processReview(mutation, data)
          }
          break
        case 'PRODUCT':
          mutation = PRODUCT_REVIEW
          if (this.$refs.reviewForm.validate()) {
            data.seasonProductID = this.activeItem.seasonProduct.seasonProductID
            this.processReview(mutation, data)
          }
          break
        case 'MANUFACTURINGORDER':
          mutation = MANUFACTURING_ORDER_REVIEW
          if (this.$refs.reviewForm.validate()) {
            data.manufacturingOrderID = this.activeItem.reviewAssetID
            this.processReview(mutation, data)
          }
          break
        default:
          this.closeDialogs()

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Review type does not exist'
          })
          break
      }
    },
    processReview(mutation, data) {
      var vm = this
      this.$apollo
        .mutate({
          mutation: mutation,
          variables: {
            input: data
          },
          update: store => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: MY_OPEN_REVIEWS
            })

            // Remove the item from the cache
            data.myOpenReviews.splice(vm.reviewIdx, 1)
            // Write our data back to the cache.
            store.writeQuery({
              query: MY_OPEN_REVIEWS,
              data
            })
          }
        })
        .then(() => {
          this.closeDialogs()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Review Submitted'
          })
        })
        .catch(() => {
          this.closeDialogs()

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Something went wrong. Please try again later'
          })
        })
    }
  }
}
</script>

<style>
.review-dialog-wrap {
  overflow-x: hidden;
}
</style>
