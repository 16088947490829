<template>
  <div>
    <!-- Budget Details -->
    <div v-if="activeItem.budget">
      <v-row>
        <v-col>
          <label class="grey--text caption">Submitted By</label>
          <div>
            {{
              activeItem.submitterFirstName + ' ' + activeItem.submitterLastName
            }}
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Submitted On</label>
          <div>{{ activeItem.createdAt | moment('LL') }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Financial Year</label>
          <div>{{ activeItem.budget.financialYear.name }}</div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Department</label>
          <div>{{ activeItem.budget.department.name }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Budget Owner</label>
          <div>
            {{
              activeItem.budget.owner.firstName +
                ' ' +
                activeItem.budget.owner.lastName
            }}
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Amount</label>
          <div class="text-h6">
            {{
              (activeItem.budget.forecastAmount / 100)
                | currency(activeItem.budget.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Description</label>
          <div v-html="activeItem.budget.description"></div>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-btn
          color="info"
          text
          :to="{
            name: 'BudgetDetail',
            params: { budgetID: activeItem.budget.id }
          }"
          target="_blank"
          >More Details</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          right
          class="mr-3"
          color="primary"
          small
          rounded
          @click="submitReview"
          >Submit Review</v-btn
        >
      </v-row>
    </div>

    <!-- Project Details -->
    <div v-if="activeItem.project">
      <v-row>
        <v-col>
          <label class="grey--text caption">Submitted By</label>
          <div>
            {{
              activeItem.submitterFirstName + ' ' + activeItem.submitterLastName
            }}
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Submitted On</label>
          <div>{{ activeItem.createdAt | moment('LL') }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Budget</label>
          <div class="text-truncate">
            <router-link
              :to="{
                name: 'BudgetDetail',
                params: { budgetID: activeItem.project.budget.id }
              }"
              >{{ activeItem.project.budget.title }}</router-link
            >
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Budget Line</label>
          <div class="text-truncate">
            {{ activeItem.project.budgetLine.title }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Department</label>
          <div>{{ activeItem.project.department.name }}</div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Project Owner</label>
          <div>
            {{
              activeItem.project.owner.firstName +
                ' ' +
                activeItem.project.owner.lastName
            }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Amount</label>
          <div class="text-h6">
            {{
              (activeItem.project.forecastAmount / 100)
                | currency(activeItem.project.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Description</label>
          <div v-html="activeItem.project.description"></div>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-btn
          color="info"
          text
          :to="{
            name: 'ProjectDetail',
            params: { projectID: activeItem.project.id }
          }"
          target="_blank"
          >More Details</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          right
          class="mr-3"
          color="primary"
          small
          rounded
          @click="submitReview"
          >Submit Review</v-btn
        >
      </v-row>
    </div>

    <!-- Purchase Order Details -->
    <div v-if="activeItem.purchaseOrder">
      <v-row>
        <v-col>
          <label class="grey--text caption">Submitted By</label>
          <div>
            {{
              activeItem.submitterFirstName + ' ' + activeItem.submitterLastName
            }}
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Submitted On</label>
          <div>{{ activeItem.createdAt | moment('LL') }}</div>
        </v-col>
      </v-row>

      <v-row v-if="activeItem.purchaseOrder.budget">
        <v-col>
          <label class="grey--text caption">Budget</label>
          <div class="text-truncate">
            <router-link
              :to="{
                name: 'BudgetDetail',
                params: { budgetID: activeItem.purchaseOrder.budget.id }
              }"
              >{{ activeItem.purchaseOrder.budget.title }}</router-link
            >
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Budget Line</label>
          <div class="text-truncate">
            {{ activeItem.purchaseOrder.budgetLine.title }}
          </div>
        </v-col>
      </v-row>

      <v-row v-if="activeItem.purchaseOrder.project">
        <v-col>
          <label class="grey--text caption">Project</label>
          <div class="text-truncate">
            <router-link
              :to="{
                name: 'ProjectDetail',
                params: {
                  purchaseOrderID: activeItem.purchaseOrder.project.id
                }
              }"
              >{{ activeItem.purchaseOrder.project.title }}</router-link
            >
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Project Line</label>
          <div class="text-truncate font-weight-medium">
            {{ activeItem.purchaseOrder.projectLine.title }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Department</label>
          <div class="font-weight-medium">
            {{ activeItem.purchaseOrder.department.name }}
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Amount</label>
          <div class="text-h6">
            {{
              (activeItem.purchaseOrder.pricing.forecastAmount / 100)
                | currency(
                  activeItem.purchaseOrder.pricing.currency.symbol,
                  2,
                  {
                    spaceBetweenAmountAndSymbol: true
                  }
                )
            }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Description</label>
          <div
            class="font-weight-medium"
            v-html="activeItem.purchaseOrder.description"
          ></div>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-btn
          color="info"
          text
          :to="{
            name: 'PurchaseOrderDetail',
            params: { purchaseOrderID: activeItem.purchaseOrder.id }
          }"
          target="_blank"
          >More Details</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          right
          class="mr-3"
          color="primary"
          small
          rounded
          @click="submitReview"
          >Submit Review</v-btn
        >
      </v-row>
    </div>

    <!-- Season Product Details -->
    <div v-if="activeItem.seasonProduct">
      <v-row>
        <v-col>
          <label class="grey--text caption">Submitted By</label>
          <div>
            {{
              activeItem.submitterFirstName + ' ' + activeItem.submitterLastName
            }}
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Submitted On</label>
          <div>{{ activeItem.createdAt | moment('LL') }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Season</label>
          <div class="text-truncate">
            {{ activeItem.seasonProduct.season.longName }} ({{
              activeItem.seasonProduct.season.shortName
            }})
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Description</label>
          <div v-html="activeItem.seasonProduct.description"></div>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-btn
          color="info"
          text
          :to="{
            name: 'ProductionProductDetail',
            params: { productID: activeItem.seasonProduct.id },
            query: { season: activeItem.seasonProduct.season.id }
          }"
          target="_blank"
          >More Details</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          right
          class="mr-3"
          color="primary"
          small
          rounded
          @click="submitReview"
          >Submit Review</v-btn
        >
      </v-row>
    </div>

    <!-- Manufacturing Order Details -->
    <div v-if="activeItem.manufacturingOrder">
      <v-row>
        <v-col>
          <label class="grey--text caption">Submitted By</label>
          <div>
            {{
              activeItem.submitterFirstName + ' ' + activeItem.submitterLastName
            }}
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Submitted On</label>
          <div>{{ activeItem.createdAt | moment('LL') }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Manufacturer</label>
          <div class="text-truncate">
            <span>{{ activeItem.manufacturingOrder.manufacturer.name }} </span>
            <span
              v-if="
                activeItem.manufacturingOrder.manufacturerAddress &&
                  activeItem.manufacturingOrder.manufacturerAddress.name
              "
            >
              -
              {{ activeItem.manufacturingOrder.manufacturerAddress.name }}</span
            >
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Manufacturing Country</label>
          <div>
            {{
              getFullCountryName(
                activeItem.manufacturingOrder.manufacturerAddress.country
              )
            }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Shipping To</label>
          <div>
            {{ activeItem.manufacturingOrder.deliverTo.address.name }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Quantity</label>
          <div>
            {{ activeItem.manufacturingOrder.total }}
          </div>
        </v-col>

        <v-col>
          <label class="grey--text caption">Cost</label>
          <div class="text-h6">
            {{
              (activeItem.manufacturingOrder.net / 100)
                | currency(activeItem.manufacturingOrder.currency.symbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label class="grey--text caption">Notes</label>
          <div v-html="activeItem.manufacturingOrder.note"></div>
        </v-col>
      </v-row>

      <v-row class="mt-6">
        <v-btn
          color="info"
          text
          :to="{
            name: 'ManufacturingOrderDetail',
            params: {
              manufacturingOrderID: activeItem.manufacturingOrder.id
            }
          }"
          target="_blank"
          >More Details</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          right
          class="mr-3"
          color="primary"
          small
          rounded
          @click="submitReview"
          >Submit Review</v-btn
        >
      </v-row>
    </div>
  </div>
</template>

<script>
import { getName } from 'country-list'

export default {
  name: 'ReviewDetails',
  props: {
    activeItem: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    submitReview() {
      this.$emit('submit-review')
    }
  }
}
</script>

<style lang="scss">
#edit-review-comments {
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  // border: 1px solid var(--v-light-theme-darken3);
  border-width: 1.2px;
  border-radius: 4px;
  // height: 100px;

  &:focus-within {
    border: 2px solid var(--v-primary-base);
  }
  .ql-editor {
    min-height: 100px !important;
  }
}
</style>
