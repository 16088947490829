<template>
  <div style="height: 100%;">
    <v-app-bar color="light-theme" fixed elevate-on-scroll app>
      <!-- <v-app-bar-nav-icon
        v-if="!hideNav"
        @click.native="toggleDrawer"
      ></v-app-bar-nav-icon> -->
      <v-toolbar-title class="primary--text pl-0 headline">
        <!-- <v-icon
          v-if="!drawer && currentApp"
          color="primary"
          style="position: relative;margin-top: -3px;"
          >{{ currentApp.icon }}</v-icon
        > -->
        <span class="app-name">{{ pageName }}</span></v-toolbar-title
      >

      <v-spacer></v-spacer>
      <span v-if="environment" class="mr-3 error--text">{{ environment }}</span>

      <!-- <v-btn icon>
        <v-badge class="align-self-center" overlap color="error notification-badge">
          <template v-slot:badge>
            <span></span>
          </template>
          <v-icon class="material-icons-outlined">notifications</v-icon>
        </v-badge>
      </v-btn>-->

      <v-btn icon exact :to="{ name: 'Dashboard' }">
        <v-icon>mdi-view-dashboard-outline</v-icon>
      </v-btn>

      <app-switcher @set-current-app="setApp"></app-switcher>

      <user-menu></user-menu>
      <!-- <template v-slot:extension>
        <v-tabs background-color="transparent" centered>
          <v-tab :to="{name: 'Home'}" exact class="text-capitalize">Personal Info</v-tab>
          <v-tab :to="{name: 'Security'}" class="text-capitalize">Security</v-tab>
        </v-tabs>
      </template>-->
      <template>
        <global-snackbar></global-snackbar>
      </template>

      <template
        v-if="breadCrumbs && breadCrumbs.length > 0"
        v-slot:extension
        crumbs-extension
      >
        <v-row no-gutters>
          <v-col cols="12" class="px-1">
            <v-card flat>
              <v-breadcrumbs
                color="info"
                :items="breadCrumbs"
                class="py-1 px-3"
              >
                <template v-slot:item="{ item }">
                  <router-link
                    v-if="!item.disabled"
                    :to="item.to"
                    class="v-breadcrumbs__item primary--text"
                  >
                    <v-breadcrumbs-item :disabled="item.disabled">
                      {{ item.text }}
                    </v-breadcrumbs-item>
                  </router-link>
                  <v-breadcrumbs-item v-else disabled>
                    {{ item.text }}
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>

    <div class="app-layout">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mdiViewDashboardOutline } from '@mdi/js'
import AppSwitcher from '@/components/core/AppSwitcher.vue'
import UserMenu from '@/components/core/UserMenu.vue'
import GlobalSnackbar from '@/components/core/GlobalSnackbar.vue'
import SEASONS from '@/graphql/Seasons.gql'
import GET_ME from '@/graphql/GetMe.gql'
import USER_APP_LIST from '@/graphql/UserApps.gql'

export default {
  name: 'ReviewLayout',
  components: {
    AppSwitcher,
    GlobalSnackbar,
    UserMenu
  },
  data: () => ({
    // drawer: null,
    dashBoardIcon: mdiViewDashboardOutline
  }),
  apollo: {
    seasons: SEASONS
  },
  computed: {
    appName() {
      return this.$route.meta.appName
    },
    pageName() {
      return this.$route.meta.pageName
    },
    currentRoute() {
      return this.$route.path
    },
    currentApp() {
      return this.$store.state.currentApp
    },
    environment() {
      return process.env.
    },
    queryParams() {
      return this.$route.query
    },
    breadCrumbs() {
      let pathArray = this.currentRoute.split('/')
      pathArray.shift()
      const breadCrumbs = []

      let breadcrumb = ''
      let lastIndexFound = 0
      for (let i = 0; i < pathArray.length; ++i) {
        if (i == 1) breadcrumb = ''
        breadcrumb = `${breadcrumb}${'/'}${pathArray[i]}`

        if (i > 0) {
          if (
            this.$route.matched[i] &&
            Object.hasOwnProperty.call(this.$route.matched[i], 'meta') &&
            Object.hasOwnProperty.call(
              this.$route.matched[i].meta,
              'breadCrumb'
            )
          ) {
            let query = i === 1 ? null : this.$route.query

            let path = pathArray[i - (i - lastIndexFound)]
              ? '/' + pathArray[i - (i - lastIndexFound)] + breadcrumb
              : breadcrumb

            breadCrumbs.push({
              to: { path: path, query: query },
              disabled:
                i + 1 === pathArray.length ||
                !Object.hasOwnProperty.call(
                  this.$route.matched[i + 1],
                  'meta'
                ) ||
                !Object.hasOwnProperty.call(
                  this.$route.matched[i + 1].meta,
                  'breadCrumb'
                ),
              text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
            })
          }
        }
      }
      return breadCrumbs
    }
  },
  created() {
    this.$vuetify.theme.themes.light.primary = '#1976D2'
    this.$vuetify.theme.themes.light.secondary = '#424242'
    this.$vuetify.theme.themes.light.accent = '#82B1FF'
    this.$vuetify.theme.themes.light.error = '#FF5252'
    this.$vuetify.theme.themes.light.info = '#2196F3'
    this.$vuetify.theme.themes.light.success = '#4CAF50'
    this.$vuetify.theme.themes.light.warning = '#FFC107'

    if (!this.$store.state.csrfToken) {
      this.$store.dispatch('GET_CSRF_TOKEN').then(response => {
        if (response && response.status === 403) {
          this.$store.commit('REMOVE_CSRF_TOKEN')
        }
        if (!this.$store.state.currentUser) {
          this.getCurrentUser()
        }
      })
    } else {
      if (!this.$store.state.currentUser) {
        this.getCurrentUser()
      }
    }
  },
  methods: {
    getCurrentUser() {
      // do something cool
      this.$apollo
        .query({
          query: GET_ME
          // fetchPolicy: 'cache-and-network'
        })
        .then(result => {
          this.$store.commit('SET_USER', result.data.getMe)

          this.$apollo
            .query({
              query: USER_APP_LIST,
              // fetchPolicy: 'cache-and-network',
              variables: {
                userID: result.data.getMe.id
              },
              update(data) {
                return data.userApps
              }
            })
            .then(result => {
              this.$store.commit('SET_USER_APPS', result.data.userApps)
            })
        })
    },

    getUserApps() {},

    setApp(app) {
      this.$store.commit('setCurrentApp', app)
    }
  }
}
</script>

<style lang="scss">
// .page-wrap {
//   --page-header-height: 64px;
//   /* --page-wrap-offset: -20px; */
// }
</style>
